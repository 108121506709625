import { Component, OnInit, HostListener, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { formatDate } from '@angular/common';
import { first } from 'rxjs/operators';

// Service
import { CalendarService } from '../../../services/calendar.service';
import { ShopService } from '../../../services/shop.service';
import { ShopcategoryService } from '../../../services/shopcategory.service';

// Model
import { Calendar } from '../../../models/calendar';
import { Shop } from '../../../models/shop';
import { Shopcategory } from '../../../models/shopcategory';

@Component({
  selector: 'app-calendar-page',
  templateUrl: './calendar.component.html'
})
export class CalendarPageComponent implements OnInit {

  // 登録フォーム
  registerForm: FormGroup;

  // カレンダー名
  calendarTitle: String;

  // 今日
  now: Date;
  nowNumber: Number = 0;

  // 選択時
  current: Date;
  nextMonth: Date;
  prevMonth: Date;

  // 今月内か否か
  outsideDayArray: {key?: string;} = {};
  
  // 日付
  dayArray: {key?: string;} = {};

  // 値
  valueArray: {key?: string;} = {};

  // カレンダー情報
  items: {key?: Calendar;} = {};

  // 最後の 1 行を表示するか否か
  hideLastLine: boolean = true;

  // ローディング
  circleLoaderClass = "hide";

  // カレンダーが表示されている(false)か否か（true）
  calendarFlg: boolean = true;

  // ダミーを入れておく
  item: Calendar = new Calendar(0,"","","");

  // カレンダー用のダイアログが表示されている(false)か否か（true）
  calendarDialogFlg: boolean = false;

  // カレンダー用のマスクが表示されている(false)か否か（true）
  calendarMaskFlg: boolean = true;

  // 現在表示されている dorpdown
  dropdownTarget: string = "";

  // 店・イベントの dropdown が表示されているか否か
  openShopDropdown: boolean = false;

  // 指定しているカテゴリー名のラベル
  shopCurrentTitle: string = "";

  // 指定しているカテゴリーのid
  shopCurrentId: string = "";

  // カテゴリ名一覧
  shopTitles: string[];

  // カテゴリid一覧
  shopIds: string[];

  // フォルダ名の dropdown の選択肢
  isShopSelectedArray: {key?: string;} = {};

  // フォルダライブラリーフォルダの情報
  isShopArray: {key?: string;} = {};

  // 選択日
  currentDate = "";

  // チェックしているか否か
  calendarStatus: {key?: boolean;} = {};

  // カレンダーの前の月を表示する(true)か否か（false）
  showPreviousMonthFlg: boolean = false;

  // カレンダーの前の月を表示する(true)か否か（false）
  showNextMonthFlg: boolean = true;

  // フォーム（必須項目）の状態を取得する
  get date() { return this.registerForm.get('date'); }
  get shop_id() { return this.registerForm.get('shop_id'); }
  get status() { return this.registerForm.get('status'); }
  get memo1() { return this.registerForm.get('memo1'); }
  get memo2() { return this.registerForm.get('memo2'); }

  // カテゴリ一覧
  shopCategories: Shopcategory[] = [];

  // no-item
  showErrorNoShopCategory = true;

  // 店舗・イベント一覧
  shops: Shop[] = [];

  // no-item
  showErrorNoShop = true;

  // initFlag
  initCompleteFlg = false;

  constructor(
    private calendarService: CalendarService,
    private shopService: ShopService,
    private shopcategoryService: ShopcategoryService,
    private formBuilder: FormBuilder,
    @Inject(LOCALE_ID) private locale: string
  ){}

    
  /* ===== LifeCycle ===== */
  
  // 開始処理
  ngOnInit() {

    console.log("onInit");

    this.initCompleteFlg = false;

    // カレンダーを非表示にする
    this.hideCalendar();

    // ローディングを表示する
    this.showLoading();

    this.shopTitles = new Array();
    this.shopIds = new Array();

    this.calendarTitle = "";
    this.now = new Date();
    var y_ = this.now.getFullYear();
    var m_ = this.now.getMonth() + 1;
    this.current = new Date(y_, m_, 0);

    this.nowNumber = y_ * 10000 + m_ * 100 + this.now.getDate();
    
    this.registerForm = this.formBuilder.group({
      date: ['', Validators.required],
      shop_id: ['', Validators.required],
      status: ['', Validators.required],
      memo1: [''],
      memo2: ['']
    });

    /* ----- 非同期処理 ----- */

    // カテゴリー一覧を取得する
    // getCategories -> getShops -> getItems -> ngOnInitComplete
    this.getCategories();

    this.ngOnInitComplete();

  }

  ngOnInitComplete() {

    // カレンダー用ダイアログのラジオボタンを更新する
    this.updateCalendarStatus(1);

    // 翌月を取得
    this.getNextMonth();

    // 前月を取得
    this.getPreviousMonth();

    // カレンダーを更新する
    this.update();

  }

  // 終了処理
  ngOnDestroy() {

  }

  /* ===== イベント ===== */

  onSubmit() {
    if (this.registerForm.invalid) {
      console.log("invalid");
      return;
    } else {

      // カレンダーを非表示にする
      this.hideCalendar();

      // ローディングを表示する
      this.showLoading();

      // カレンダー用マスクを隠す
      this.hideCalendarMask();
      
      // ダイアログを隠す
      this.hideCalendarDialog();
      
      var data: Object = {
        date: this.registerForm.get('date').value,
        shop_id: parseInt(this.registerForm.get('shop_id').value,10),
        status: String(this.registerForm.get('status').value),
        memo1: this.registerForm.get('memo1').value,
        memo2: this.registerForm.get('memo2').value,
      }
      
      this.calendarService.update(data).pipe(first()).subscribe(result => {
        
        console.log(result);
        // 指定している月のカレンダー状況を取得する
        this.getItems();

      });
    
    }
  }

  // 画面のどこかをクリックした場合
  @HostListener('document:click', ['$event'])
  onClick(event) {
    var t_ = event.target.id;
    if (t_ == "") {
      t_ = event.target.parentNode.id;
      if (t_ == "") {
        t_ = event.target.parentNode.parentNode.id;
      }
    }
    //if(t_ != this.dropdownTarget) {
    if(t_ == "") {
      // console.log("Click");
      this.openShopDropdown = false;
      this.dropdownTarget = "";
    }
  }

  // カレンダーの日付をクリックする
  onClickDate(v: string) {
    var d_ = parseInt(v, 10) % 100;
    if(this.nowNumber <= parseInt(v, 10)){
      if (v != "") {
        if (this.items[d_].date == v) {
          this.registerForm.controls.date.setValue(parseInt(v,10));
          this.registerForm.controls.memo1.setValue(this.items[d_].memo1);
          this.registerForm.controls.memo2.setValue(this.items[d_].memo2);
          this.updateCalendarStatus(parseInt(this.items[d_].status,10));
        } else {
          this.registerForm.controls.date.setValue(parseInt(v,10));
          this.registerForm.controls.memo1.setValue("");
          this.registerForm.controls.memo2.setValue("");
          this.updateCalendarStatus(1);
        }
        this.registerForm.controls.date.setValue(parseInt(v,10));
        // カレンダー用マスクを表示する
        this.showCalendarMask();
        // ダイアログを表示する
        this.showCalendarDialog();
        this.currentDate = v;
      }
    }
  }

  // カレンダーダイアログのキャンセルをクリックする
  onClickCalendarDialogCancelButton() {
    // カレンダー用マスクを隠す
    this.hideCalendarMask();
    // ダイアログを隠す
    this.hideCalendarDialog();
    // カレンダーを表示する
    this.showCalendar();
    // ローディングを隠す
    this.hideLoading();
  }

  // ステータスのドロップダウンの状態を変更する
  onStatusChange(v:number) {
    this.updateCalendarStatus(v);
  }

  // 店名のドロップダウンのトグル処理
  onShopDropdownTrigger(event) {
    event.preventDefault();
    this.openShopDropdown = !this.openShopDropdown;
  }

  // カテゴリー名のドロップダウンの状態を変更する
  onShopDropdownChange(v) {
    if (v != this.shopCurrentId) {
      this.updateSelectedShopIdLocalStorage(v)
      this.registerForm.controls.shop_id.setValue(v);
      this.shopCurrentId = v;
      for (let i = 0; i < this.shopIds.length; i++) {
        let id = this.shopIds[i];
        if (id == v) {
          this.shopCurrentTitle = this.shopTitles[i];
          this.isShopSelectedArray[id] = true;
        } else {
          this.isShopSelectedArray[id] = false;
        }
      }
      if (this.initCompleteFlg == true) {
        this.refresh();
      }
    }
  }

  /* ===== メソッド ===== */

  // カレンダーを表示する
  showCalendar() {
    this.calendarFlg = false;
  }
  
  // カレンダーを隠す
  hideCalendar() {
    this.calendarFlg = true;
  }

  // ローディングを表示する
  showLoading() {
    this.circleLoaderClass = "show";
  }
  
  // ローディングを隠す
  hideLoading() {
    this.circleLoaderClass = "hide";
  }

  // カレンダー用マスクを表示する
  showCalendarMask() {
    this.calendarMaskFlg = false;
  }
  
  // カレンダー用マスクを隠す
  hideCalendarMask() {
    this.calendarMaskFlg = true;
  }

  // 次の月に変える
  setNextMonth() {
    this.current = this.nextMonth;
    this.getNextMonth();
    this.getPreviousMonth();
    this.update();
    
    // カレンダーを非表示にする
    this.hideCalendar();

    // ローディングを表示する
    this.showLoading();

    // 指定している月のカレンダー状況を取得する
    this.getItems();
  }

  // 次の月を取得する
  getNextMonth() {
    var nextYear = this.current.getFullYear();
    var nextMonth = this.current.getMonth() + 1;
    if (nextMonth == 12) {
      nextYear++;
      nextMonth = 1;
    } else {
      nextMonth++;
    }
    this.nextMonth = new Date(nextYear, nextMonth, 0);
  }

  // 前の月に変える
  setPreviousMonth() {
    this.current = this.prevMonth;
    this.getNextMonth();
    this.getPreviousMonth(); 
    this.update();
    
    // カレンダーを非表示にする
    this.hideCalendar();

    // ローディングを表示する
    this.showLoading();

    // 指定している月のカレンダー状況を取得する
    this.getItems();
  }

  // 前の月を取得する
  getPreviousMonth() {
    var prevYear = this.current.getFullYear();
    var prevMonth = this.current.getMonth() + 1;
    if (prevMonth == 1) {
      prevYear--;
      prevMonth = 12;
    } else {
      prevMonth--;
    }
    this.prevMonth = new Date(prevYear, prevMonth, 0);
    
    // 今月末
    var now_ = new Date(this.now.getFullYear(), this.now.getMonth()+1, 0);
    if (now_ <= this.prevMonth) {
      // console.log("前の月を出す");
      this.showPreviousMonthFlg = true;
    } else {
      // console.log("前の月を出さない");
      this.showPreviousMonthFlg = false;
    }
    
  }

  // カレンダーを更新する
  update(){

    var y_ = this.current.getFullYear();
    var m_ = this.current.getMonth() + 1;

    var count_ = new Date(y_, m_, 0).getDate();
    
    for (var i = 1; i <= count_; i++) {
      var t_ = y_ * 10000 + m_ * 100 + i;
      if (this.nowNumber <= t_) {
        this.items[i] = new Calendar(0, "", "", "");
      } else {
        this.items[i] = new Calendar(0, "00", "", "");
      }
    }

    this.calendarTitle = formatDate(this.current, "yyyy年M月", this.locale);
    var y_ = this.current.getFullYear();
    var m_ = this.current.getMonth() + 1;
    var c_ = this.current.getDate();
    var d_ = new Date(y_, m_ - 1, 1).getDay();

    for (var i = 0; i < d_; i++) {
      this.outsideDayArray[0 + i] = true;
      this.dayArray[0 + i] = this.prevMonth.getDate() - (d_ - i - 1);
      this.valueArray[0 + i] = "";
    }

    for (var i = 0; i < c_; i++) {
      this.outsideDayArray[i + d_] = false;
      this.dayArray[i + d_] = i + 1;
      var z1_ = "0";
      var z2_ = "0";
      if (9 < m_) {
        z1_ = "";
      }
      if (9 <= i) {
        z2_ = "";
      }
      this.valueArray[i + d_] = String(y_) + z1_ + String(m_) + z2_ + String(i + 1);
    }

    for (var i = d_ + c_; i < 42; i++) {
      this.outsideDayArray[0 + i] = true;
      this.dayArray[i] = i - (d_ + c_ ) + 1;
      this.valueArray[i] = "";
    }

    this.hideLastLine = true;
    
    for (var i = 41; 34 < i; i--) {
      if (this.outsideDayArray[i] == false) {
        this.hideLastLine = false;
        break;
      }
    }
    
  }

  // yyyymmの予約カレンダーを取得する
  getItems() {

    var y_ = this.current.getFullYear();
    var m_ = this.current.getMonth() + 1;

    var count_ = new Date(y_, m_, 0).getDate();
    for (var i = 1; i <= count_; i++) {
      var t_ = y_ * 10000 + m_ * 100 + i;
      if (this.nowNumber <= t_) {
        this.items[i] = new Calendar(0, "", "", "");
      } else {
        this.items[i] = new Calendar(0, "00", "", "");
      }
    }
    
    var yyyy = y_.toString();
    var mm = "";
    if (m_ < 10) {
      mm = "0";
    }
    mm += m_.toString();

    var items_ = this.items;

    var data: Object = { month: yyyy + mm, shopId:this.shopCurrentId}

    this.calendarService.getItems(data).subscribe(itemList => {

      // itemsを更新する
      if (0 < itemList['data'].length) {
        // 日を抜き出す
        for (var i = 0; i < itemList['data'].length; i++) {
          var date_ = itemList['data'][i].date % 100;
          if (this.nowNumber <= itemList['data'][i].date) {
            var v_ = itemList['data'][i];
            items_[date_] = new Calendar(v_.date, v_.status, v_.memo1, v_.memo2);
          }
        }
        console.log(items_);
      } else {
        console.log("none");
      }

      this.initCompleteFlg = true;

      // カレンダーを表示する
      this.showCalendar();
      
      // ローディングを隠す
      this.hideLoading();

    });
  
  }

  // カレンダー用ダイアログを表示する
  showCalendarDialog() {
    this.calendarDialogFlg = true;
  }
  
  // カレンダー用ダイアログを隠す
  hideCalendarDialog() {
    this.calendarDialogFlg = false;
  }

  // カレンダー用ダイアログのラジオボタンを更新する
  updateCalendarStatus(v: number) {
    this.calendarStatus[1] = false;
    this.calendarStatus[2] = false;
    this.calendarStatus[3] = false;
    this.calendarStatus[98] = false;
    this.calendarStatus[99] = false;
    this.calendarStatus[v] = true;
    this.registerForm.controls.status.setValue(v);
  }

  // 画面を更新する
  refresh() {
    if (this.initCompleteFlg == true) {
      // カレンダーを隠す
      this.hideCalendar();
      
      // ローディングを表示する
      this.showLoading();

      this.getItems();
    }
  }

  /* ----- カテゴリー ----- */

  // カテゴリー一覧を取得する
  getCategories() {
    this.shopcategoryService.getItems().pipe(first()).subscribe(items => {
      if (items['status'] == "success") {
        this.showErrorNoShopCategory = false;
        this.shopCategories = items['items'];
      } else if (items['status'] == "no item") {
        this.showErrorNoShopCategory = true;
      } else {
        this.showErrorNoShopCategory = false;
      }
      this.getShops();
    });
  }

  /* ----- 店舗・イベント ----- */

  // 店舗・イベント一覧を取得する
  getShops() {
    this.shopService.getItems().pipe(first()).subscribe(items => {
      if (items['status'] == "success") {
        this.showErrorNoShop = false;
        this.shops = items['items'];
        for (let i = 0; i < items['items'].length; i++) {
          let v = items['items'][i]['category_id'];
          var categoryTitle = "";
          for (let j = 0; j < this.shopCategories.length; j++) {
            if (v == this.shopCategories[j]['id']) {
              categoryTitle = this.shopCategories[j]['title'];
            }
          }
          let v2 = items['items'][i]['id'];
          this.shopTitles.push(items['items'][i]['title'] + " - " + categoryTitle);
          this.shopIds.push(v2);
          this.isShopSelectedArray[v2] = false;
          this.isShopArray[v2] = items['items'][i]['title'] + " - " + categoryTitle;
        }
        this.getSelectedShopIdLocalStorage();
      } else if (items['status'] == "no item") {
        this.showErrorNoShop = true;
      } else {
        this.showErrorNoShop = false;
      }
      this.getItems();
    });
  }


  /* ----- Local Storage ----- */
  
  // get
  getSelectedShopIdLocalStorage() {
    let v = localStorage.getItem("calendarSelectShopId");
    if (v == "null" || v == null) {
      this.onShopDropdownChange("1");
    } else {
      this.onShopDropdownChange(v);
    }
  }

  //update
  updateSelectedShopIdLocalStorage(v) {
    localStorage.setItem("calendarSelectShopId", v);
  }
  
}
