<form [formGroup]="registerForm" class="spectrum-Form spectrum-Form--labelsAbove">

  <div class="nd-row">

    <div class="nd-col-xs-12 nd-col-sm-6">

      <div class="spectrum-Form-item">
        <label for="category" class="spectrum-FieldLabel">カテゴリ</label>
        <div id="category">
          <div class="spectrum-Dropdown">
            <button (click)="onCategoryDropdownTrigger($event)" id="categoryTrigger" class="spectrum-FieldButton spectrum-Dropdown-trigger" aria-haspopup="true">
              <span class="spectrum-Dropdown-label">{{categoryCurrentTitle}}</span>
              <svg class="spectrum-Icon spectrum-UIIcon-ChevronDownMedium spectrum-Dropdown-icon" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-css-icon-ChevronDownMedium" />
              </svg>
            </button>

            <div [ngClass]="{'is-open': openCategoryDropdown}" class="spectrum-Popover spectrum-Popover--bottom spectrum-Dropdown-popover" style="max-width: 100%; z-index: 1;">
              <ul *ngFor="let category of categoryTitles; let i = index" class="spectrum-Menu" role="listbox">

                <li (click)='onCategoryDropdownChange(categoryIds[i])' [ngClass]="{'is-selected': isCategorySelectedArray[categoryIds[i]]}" class="spectrum-Menu-item" tabindex="0">
                  <span class="spectrum-Menu-itemLabel">{{category}}</span>
                  <svg class="spectrum-Icon spectrum-UIIcon-CheckmarkMedium spectrum-Menu-checkmark spectrum-Menu-itemIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-CheckmarkMedium"></use>
                  </svg>
                </li>

              </ul>
            </div>
          </div>
        </div>
        <input formControlName="category_id" type="hidden" value="">
      </div>

    </div>
  </div><!-- /.nd-row -->

    <div class="nd-row">

      <div class="nd-col-xs-12 nd-col-sm-6">

        <div class="spectrum-Form-item">
          <label for="lifestory" class="spectrum-FieldLabel">店舗・イベント名</label>
          <input formControlName="title" type="text" placeholder="" value="" class="spectrum-Textfield">
        </div>

        <div *ngIf="title.invalid && (title.dirty || title.touched)">
          <div class="nd-Error-message" *ngIf="title.errors.required">
            タイトルは必ず入力してください。
          </div>
        </div>

      </div><!-- /.nd-col-xs-12 -->

    </div><!-- /.nd-row -->

    <div class="nd-row">

      <div class="nd-col-xs-12 nd-col-sm-9">

        <div class="spectrum-Form-item">
          <label class="spectrum-Form-itemLabel" for="count_child">状態</label>
          <div class="spectrum-Form-itemField nd-radio-buttons">
            <div [ngClass]="{'checked': shopStatus[1]}" (click)="onStatusChange(1)" class="spectrum-Radio">
              <span class="spectrum-Radio-button"></span>
              <label class="spectrum-Radio-label" for="type-1">有効</label>
            </div> 
            <div [ngClass]="{'checked': shopStatus[0]}" (click)="onStatusChange(0)" class="spectrum-Radio">
              <span class="spectrum-Radio-button"></span>
              <label class="spectrum-Radio-label" for="type-2">無効</label>
            </div>
          </div>
          <input type="hidden" formControlName="status" value="">
        </div>

      </div><!-- /.nd-col-xs-9 -->

    </div><!-- /.nd-row -->


    <div class="nd-row">

      <div class="nd-col-xs-4 nd-col-sm-3">

        <div class="spectrum-Form-item">
          <label class="spectrum-Form-itemLabel" for="rank">表示順</label>
          <div class="spectrum-Form-itemField">
            <div class="spectrum-Stepper">
              <input id="rank" formControlName="rank" type="text" class="spectrum-Textfield spectrum-Stepper-input" placeholder="" min="0" max="100" step="1" value="">
              <span class="spectrum-Stepper-buttons">
                <button class="spectrum-ActionButton spectrum-Stepper-stepUp" (click)="onIncreaseRank($event)">
                  <svg class="spectrum-Icon spectrum-UIIcon-ChevronUpSmall spectrum-Stepper-stepUpIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-ChevronUpSmall" />
                  </svg>
                </button>
                <button class="spectrum-ActionButton spectrum-Stepper-stepDown" (click)="onDecreaseRank($event)">
                  <svg class="spectrum-Icon spectrum-UIIcon-ChevronDownSmall spectrum-Stepper-stepDownIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-ChevronDownSmall" />
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="rank.invalid && (rank.dirty || rank.touched)">
          <div class="nd-Error-message" *ngIf="rank.errors.required">
            表示順は必ず入力してください。
          </div>
        </div>

      </div><!-- /.nd-col-xs-4 nd-col-sm-3 -->

    </div><!-- /.nd-row -->

    <div class="nd-row">

      <div class="nd-col-xs-12">
  
        <div class="spectrum-ButtonGroup">
          <button (click)="onSubmit()" [disabled]="!registerForm.valid" class="spectrum-Button nd-Button-register">
            <span class="spectrum-Button-label">{{actionButtonLabel}}</span>
          </button>
          <button (click)="onClickCancelButton()" [class]="cancelButtonClass">
            <span class="spectrum-Button-label">キャンセル</span>
          </button>
        </div>

        <input type="hidden" formControlName="id" value="">
  
      </div><!-- /.nd-col-xs-12 -->
      
    </div><!-- /.nd-row -->

</form>

<div [class]="contentsClass">

  <section class="nd-section">

    <div class="nd-row">
        
      <div class="nd-col-xs-12">

        <h2 class="nd-section-heading">店舗・イベント一覧</h2>

      </div>

      <div class="nd-col-xs-12">

        <div *ngIf="showErrorNoItem">
          <div class="nd-col-xs-12">
            <div class="nd-Error-message">
              店舗・イベントが登録されていません。
            </div>
          </div>
        </div>

        <div *ngIf="!showErrorNoItem">
        
          <table class="spectrum-Table nd-full-width">
            <thead class="spectrum-Table-head">
              <tr>
                <th class="spectrum-Table-headCell calendar-setting-shop-list">
                  <div class="nd-label-title">店舗・イベント名</div>
                  <div class="nd-label-category">カテゴリ</div>
                  <div class="nd-label-status">状態</div>
                  <div class="nd-label-rank">表示順</div>
                  <div class="nd-label-action">操作</div>
                </th>
              </tr>
            </thead>

            <tbody class="spectrum-Table-body">
              <calendar-setting-shop-list-item *ngFor="let item of items; let i = index" [categories]="itemListCategoryTitles" [item]="item" (event)="onReceiveEventFromChild($event)" class="spectrum-Table-row" tabindex="0">
              </calendar-setting-shop-list-item>
            </tbody>
          </table>

        </div>

      </div>

    </div>

  </section>

</div>

<div [class]="circleLoaderClass">
  <app-circle-loader></app-circle-loader>
</div>