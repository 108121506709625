<form [formGroup]="registerForm" class="spectrum-Form spectrum-Form--labelsAbove">

  <div [class]="contentsClass">
    <!--
    <div class="nd-row">

      <div class="nd-col-xs-4 nd-col-sm-3">
        
        <div class="spectrum-Form-item">
          <label class="spectrum-Form-itemLabel" for="calendarSettingLimitMonth">何日先から表示するか（今日の場合は0日）</label>
          <div class="spectrum-Form-itemField">
            <div class="spectrum-Stepper">
              <input id="calendarSettingStartDay" formControlName="calendarSettingStartDay" type="text" class="spectrum-Textfield spectrum-Stepper-input" placeholder="" min="0" max="100" step="1" value="">
              <span class="spectrum-Stepper-buttons">
                <button class="spectrum-ActionButton spectrum-Stepper-stepUp" (click)="onIncreaseStartDay($event)">
                  <svg class="spectrum-Icon spectrum-UIIcon-ChevronUpSmall spectrum-Stepper-stepUpIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-ChevronUpSmall" />
                  </svg>
                </button>
                <button class="spectrum-ActionButton spectrum-Stepper-stepDown" (click)="onDecreaseStartDay($event)">
                  <svg class="spectrum-Icon spectrum-UIIcon-ChevronDownSmall spectrum-Stepper-stepDownIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-ChevronDownSmall" />
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="calendarSettingStartDay.invalid && (calendarSettingStartDay.dirty || calendarSettingStartDay.touched)">
          <div class="nd-Error-message" *ngIf="calendarSettingStartDay.errors.required">
            この項目は必ず入力してください。
          </div>
        </div>
      
      </div>

    </div>

    <div class="nd-row">

      <div class="nd-col-xs-4 nd-col-sm-3">

        <div class="spectrum-Form-item">
          <label class="spectrum-Form-itemLabel" for="calendarSettingLimitMonth">何ヶ月先の月末まで表示するか</label>
          <div class="spectrum-Form-itemField">
            <div class="spectrum-Stepper">
              <input id="calendarSettingLimitMonth" formControlName="calendarSettingLimitMonth" type="text" class="spectrum-Textfield spectrum-Stepper-input" placeholder="" min="0" max="100" step="1" value="">
              <span class="spectrum-Stepper-buttons">
                <button class="spectrum-ActionButton spectrum-Stepper-stepUp" (click)="onIncreaseLimitMonth($event)">
                  <svg class="spectrum-Icon spectrum-UIIcon-ChevronUpSmall spectrum-Stepper-stepUpIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-ChevronUpSmall" />
                  </svg>
                </button>
                <button class="spectrum-ActionButton spectrum-Stepper-stepDown" (click)="onDecreaseLimitMonth($event)">
                  <svg class="spectrum-Icon spectrum-UIIcon-ChevronDownSmall spectrum-Stepper-stepDownIcon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-css-icon-ChevronDownSmall" />
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="calendarSettingLimitMonth.invalid && (calendarSettingLimitMonth.dirty || calendarSettingLimitMonth.touched)">
          <div class="nd-Error-message" *ngIf="calendarSettingLimitMonth.errors.required">
            この項目は必ず入力してください。
          </div>
        </div>

      </div>

    </div>

    <div class="nd-row">

      <div class="nd-col-xs-12">
  
        <div class="spectrum-ButtonGroup">
          <button (click)="onSubmit()" [disabled]="!registerForm.valid" class="spectrum-Button nd-Button-register">
            <span class="spectrum-Button-label">{{actionButtonLabel}}</span>
          </button>
          <button (click)="onClickCancelButton()" [class]="cancelButtonClass">
            <span class="spectrum-Button-label">キャンセル</span>
          </button>
        </div>
  
      </div>
      
    </div> -->

  </div>

</form>

<div [class]="circleLoaderClass">
  <app-circle-loader></app-circle-loader>
</div>