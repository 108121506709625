import { Component, OnInit, HostListener} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// Service
import { LoadingService } from '../../../services/loading.service';
import { AlertService } from '../../../services/alert.service';
import { DialogService } from '../../../services/dialog.service';
import { SettingService } from '../../../services/setting.service';

// Model

@Component({
  selector: 'calendar-setting-setting',
  templateUrl: './calendar-setting-setting.component.html'
})
export class CalendarSettingSettingComponent implements OnInit {

  // 登録フォーム
  registerForm: FormGroup;

  // ローディング
  circleLoaderClass = "hide";

  // コンテンツ
  contentsClass = "nd-col-xs-12 hide";

  // ボタンのラベル
  actionButtonLabel: String;

  // 編集しないボタンのクラス
  cancelButtonClass = "spectrum-Button nd-Button-cancel hide";

  // 今のモード
  currentMode = "update";

  // アラートの実行ボタンの状態を監視
  alertSubscription: Subscription;

  // ダイアログの実行ボタンの状態を監視
  dialogSubscription: Subscription;

  calendarSettingLimitMonthId: Number;
  calendarSettingStartDayId: Number;

  // フォーム（必須項目）の状態を取得する
  get calendarSettingLimitMonth() { return this.registerForm.get('calendarSettingLimitMonth'); }
  get calendarSettingStartDay() { return this.registerForm.get('calendarSettingStartDay'); }

  /* ===== lifecycle ===== */

  constructor(
    private loadingService: LoadingService,
    private alertService: AlertService,
    private dialogService: DialogService,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    this.hideLoading();
    this.showContents();

    this.actionButtonLabel = "更新する";

    // フォームを初期化
    this.registerForm = this.formBuilder.group({
      calendarSettingLimitMonth: ['', Validators.required],
      calendarSettingStartDay: ['', Validators.required]
    });

    // アラートの実行ボタンを使えるようにする
    this.alertService.setAction(true);
    this.alertSubscription = this.alertService.action.subscribe((data: string) => console.log("subscribe()", data));

    // ダイアログの実行ボタンを使えるようにする
    this.dialogService.setAction(true);
    this.dialogSubscription = this.dialogService.action.subscribe((data: string[]) => {
      if(data[0] == "app-calendar-setting") {
        if (data[1] == "update") {
          this.updateAction();
        }
      } else {
        console.log("【 app-calendar-setting.component 】実行しません");
      }
    });

    this.getSetting();
  }

  // 終了処理
  ngOnDestroy() {

  }

  /* ===== イベント ===== */

  // 編集しないボタンをタップ
  onClickCancelButton(){
    this.updateMode();
  }

  // 表示順を増やしたとき
  onIncreaseLimitMonth(event) {
    event.preventDefault();
    var n_:number = Number(this.registerForm.controls.calendarSettingLimitMonth.value);
    if (n_ < 24) {
      this.registerForm.controls.calendarSettingLimitMonth.setValue(n_ + 1);
    }
  }
  
  // 表示順を減らしたとき
  onDecreaseLimitMonth(event) {
    event.preventDefault();
    var n_:number = Number(this.registerForm.controls.calendarSettingLimitMonth.value);
    if (0 < n_) {
      this.registerForm.controls.calendarSettingLimitMonth.setValue(n_ - 1);
    }
  }

  // 表示順を増やしたとき
  onIncreaseStartDay(event) {
    event.preventDefault();
    var n_:number = Number(this.registerForm.controls.calendarSettingStartDay.value);
    if (n_ < 31) {
      this.registerForm.controls.calendarSettingStartDay.setValue(n_ + 1);
    }
  }
  
  // 表示順を減らしたとき
  onDecreaseStartDay(event) {
    event.preventDefault();
    var n_:number = Number(this.registerForm.controls.calendarSettingStartDay.value);
    if (0 < n_) {
      this.registerForm.controls.calendarSettingStartDay.setValue(n_ - 1);
    }
  }
  
  // 送信する
  onSubmit() {
    console.log(this.currentMode);
    if (this.currentMode == "update") {
      if (this.registerForm.invalid) {
        console.log("invalid");
        console.log(this.registerForm.value);
        return;
      } else {
        console.log("submit");
        this.showUpdateDialog();
      }
    }
  }

/* ===== メソッド ===== */

  // ローディングを表示する
  showLoading() {
    this.circleLoaderClass = "show";
  }
  
  // ローディングを隠す
  hideLoading() {
    this.circleLoaderClass = "hide";
  }

  // コンテンツを表示する
  showContents() {
    this.contentsClass = "nd-col-xs-12 show";
  }

  // コンテンツを非表示する
  hideContents() {
    this.contentsClass = "nd-col-xs-12 hide";
  }

  // 設定一覧を取得する
  getSetting() {
    this.showLoading();
    this.hideContents();
    this.settingService.getItems().pipe(first()).subscribe(items => {
      if (items['status'] == "success") {
        console.log(items);
        this.calendarSettingLimitMonthId = items["data"]["calendar-setting-limit-month"]["id"];
        this.calendarSettingStartDayId = items["data"]["calendar-setting-start-day"]["id"];
        this.registerForm.controls.calendarSettingLimitMonth.setValue(items["data"]["calendar-setting-limit-month"]["value"]);
        this.registerForm.controls.calendarSettingStartDay.setValue(items["data"]["calendar-setting-start-day"]["value"]);
      }
      this.hideLoading();
      this.showContents();
    });

  }

  // 編集モードに変更する
  updateMode() {
    this.currentMode = "update";
    this.actionButtonLabel = "編集する";
  }

  // 編集する
  updateAction() {
    this.hideContents();
    this.showLoading();
    
    var data_ = new FormData();

    data_.append('calendar-setting-limit-month-id', String(this.calendarSettingLimitMonthId));
    data_.append('calendar-setting-limit-month-v', this.registerForm.value['id']);
    data_.append('calendar-setting-start-day-id', String(this.calendarSettingLimitMonthId));
    data_.append('calendar-setting-start-day-v', this.registerForm.value['title']);
  
    this.settingService.update(data_).pipe(first()).subscribe(items => {
      this.showCompleteAlert();
      this.getSetting();
      this.updateMode();
    });
    
  }

  /* ===== 確認ダイアログ ===== */

  /** 
   * 確認ダイアログを表示する
   * array[
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
   showUpdateDialog() {
    // ダイアログに命令を登録する
    var order: string[] = ["app-calendar-setting", "update"];
    this.dialogService.setOrder(order);
    var dialogArray: string[] = ['更新する','更新しない','設定情報を更新しますか?','更新する場合は「更新する」ボタンをクリックしてください。','nd-Button-register'];
    this.dialogService.requestDialog(dialogArray);
  }


  /* ===== 完了アラート ===== */

  /** 
   * アラートを表示する
   * array[
   *  アラートの種類（info, help, success, warning, error）,
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
  showCompleteAlert() {
    var alertArray: string[] = ['success','','','カレンダー設定','設定情報を更新しました'];
    this.alertService.requestAlert(alertArray);
  }
  

}