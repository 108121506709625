import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from '../../environments/environment';

// Service
import { AuthService } from './auth.service';

// Model
import { Setting } from '../models/setting';


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  public header :Object;
  public headerPost: Object;

  private eventSubject = new Subject<Setting>();
  public eventObservable$ = this.eventSubject.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ){
    this.header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.authService.currentAccount.token}`)
    };
  }
  
  // 設定一覧を取得する
  public getItems() {
    return this.http.get<any>(environment.apiUrl + "/calendarsetting");
  }

  // 更新する
  public update(data: FormData) {
    return this.http.post<any>(environment.apiUrl + "/calendarsetting/update", data, this.header);
  }
  
}
