import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from '../../environments/environment';

// Service
import { AuthService } from './auth.service';

// Model
import { Calendar } from '../models/calendar';


@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  // 今見ている予約情報
  private currentItem: Calendar;

  public header :Object;
  public headerPost: Object;

  private eventSubject = new Subject<Calendar>();
  public eventObservable$ = this.eventSubject.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ){
    this.currentItem = null;
    this.header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.authService.currentAccount.token}`)
    };
  }
  
  // 年と月を指定した予約一覧を取得する
  getItems(data: Object) {
    console.log(data);
    return this.http.post(environment.apiUrl + "/calendar/get", data, this.header);
  }

  // 年月日を指定した予約を更新する
  public update(data: Object) {
    return this.http.post<any>(environment.apiUrl + "/calendar/update", data, this.header);
  }

  // 年月日を指定した予約をクリアする
  public delete(yyyymmdd: string) {
    
  }
  
}