import { Component, OnInit, HostListener} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// Service
import { ShopService } from '../../../services/shop.service';
import { ShopcategoryService } from '../../../services/shopcategory.service';
import { AlertService } from '../../../services/alert.service';
import { DialogService } from '../../../services/dialog.service';

// Model
import { Shop } from '../../../models/shop';

@Component({
  selector: 'calendar-setting-shop',
  templateUrl: './calendar-setting-shop.component.html'
})
export class CalendarSettingShopComponent implements OnInit {

  // 登録フォーム
  registerForm: FormGroup;

  // ローディング
  circleLoaderClass = "hide";

  // コンテンツ
  contentsClass = "nd-col-xs-12 hide";

  // 現在表示されている dorpdown
  dropdownTarget: string = "";

  // カテゴリーの dropdown が表示されているか否か
  openCategoryDropdown: boolean = false;

  // 指定しているカテゴリー名のラベル
  categoryCurrentTitle: string = "";

  // カテゴリ名一覧
  categoryTitles: string[];

  // カテゴリid一覧
  categoryIds: string[];

  // フォルダライブラリーフォルダの情報
  itemListCategoryTitles: {key?: string;} = {};

  // フォルダ名の dropdown の選択肢
  isCategorySelectedArray: {key?: string;} = {};

  // フォルダライブラリーフォルダの情報
  isCategoryArray: {key?: string;} = {};

  // ボタンのラベル
  actionButtonLabel: String;

  // 編集しないボタンのクラス
  cancelButtonClass = "spectrum-Button nd-Button-cancel hide";

  // 今のモード
  currentMode = "register";

  // Shop一覧
  items: Shop[] = [];

  // Shop
  item: Shop;

  // チェックしているか否か
  shopStatus: {key?: boolean;} = {};

  // Shop があるか無いか
  showErrorNoItem = true;

  // Category があるか無いか
  showErrorNoCategory = true;

  // アラートの実行ボタンの状態を監視
  alertSubscription: Subscription;

  // ダイアログの実行ボタンの状態を監視
  dialogSubscription: Subscription;

  // フォーム（必須項目）の状態を取得する
  get title() { return this.registerForm.get('title'); }
  get category_id() { return this.registerForm.get('category_id'); }
  get status() { return this.registerForm.get('status'); }
  get rank() { return this.registerForm.get('rank'); }

  /* ===== lifecycle ===== */

  constructor(
    private shopService: ShopService,
    private shopcategoryService: ShopcategoryService,
    private alertService: AlertService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    this.actionButtonLabel = "登録する";

    this.categoryTitles = new Array();
    this.categoryIds = new Array();

    // フォームを初期化
    this.registerForm = this.formBuilder.group({
      id: [''],
      category_id:['', Validators.required],
      title: ['', Validators.required],
      status:['', Validators.required],
      rank: ['', Validators.required]
    });

    this.registerForm.controls.rank.setValue(0);
    
    this.updateShopStatus(1);

    // アラートの実行ボタンを使えるようにする
    this.alertService.setAction(true);
    this.alertSubscription = this.alertService.action.subscribe((data: string) => console.log("subscribe()", data));

    // ダイアログの実行ボタンを使えるようにする
    this.dialogService.setAction(true);
    this.dialogSubscription = this.dialogService.action.subscribe((data: string[]) => {
      if(data[0] == "app-calendar-shop") {
        if (data[1] == "register") {
          this.registerAction();
        } else if (data[1] == "update") {
          this.updateAction();
        } else if (data[1] == "delete") {
          this.deleteAction();
        }
      } else {
        console.log("【 app-calendar-shop.component 】実行しません");
      }
    });

    this.getCategories();
  }

  // 終了処理
  ngOnDestroy() {

  }

  /* ===== イベント ===== */

  // 画面のどこかをクリックした場合
  @HostListener('document:click', ['$event'])
  onClick(event) {
    var t_ = event.target.id;
    if (t_ == "") {
      t_ = event.target.parentNode.id;
      if (t_ == "") {
        t_ = event.target.parentNode.parentNode.id;
      }
    }
    //if(t_ != this.dropdownTarget) {
    if(t_ == "") {
      // console.log("Click");
      this.openCategoryDropdown = false;
      this.dropdownTarget = "";
    }
  }

  // 編集しないボタンをタップ
  onClickCancelButton(){
    this.registerMode();
  }

  // フォルダ一覧からフォルダ名を選ぶ
  onReceiveEventFromChild(data: any[]) {
    if (data[0] == "edit") {
      this.item = data[1];
      // 編集モードに変更する
      this.editMode();
    } else {
      this.item = data[1];
      // 削除する
      this.showDeleteDialog();
    }
  }

  // カテゴリー名のドロップダウンのトグル処理
  onCategoryDropdownTrigger(event) {
    event.preventDefault();
    console.log(event.currentTarget.id);
    //this.dropdownTarget = event.currentTarget.id;
    this.openCategoryDropdown = !this.openCategoryDropdown;
  }

  // カテゴリー名のドロップダウンの状態を変更する
  onCategoryDropdownChange(v) {
    this.registerForm.controls.category_id.setValue(v);
    for (let i = 0; i < this.categoryIds.length; i++) {
      let id = this.categoryIds[i];
      if (id == v) {
        this.isCategorySelectedArray[id] = true;
        this.categoryCurrentTitle = this.categoryTitles[i];
      } else {
        this.isCategorySelectedArray[id] = false;
      }
    }
  }

  // 表示順を増やしたとき
  onIncreaseRank(event) {
    event.preventDefault();
    var n_:number = this.registerForm.controls.rank.value;
    if (n_ < 20) {
      this.registerForm.controls.rank.setValue(n_ + 1);
    }
  }
  
  // 表示順を減らしたとき
  onDecreaseRank(event) {
    event.preventDefault();
    var n_:number = this.registerForm.controls.rank.value;
    if (0 < n_) {
      this.registerForm.controls.rank.setValue(n_ - 1);
    }
  }

  // 送信する
  onSubmit() {
    console.log(this.currentMode);
    if (this.currentMode == "register") {
      if (this.registerForm.invalid) {
        console.log("invalid");
        console.log(this.registerForm.value);
        return;
      } else {
        console.log("submit");
        this.showRegisterDialog();
      }
    } else if (this.currentMode == "edit") {
      if (this.registerForm.invalid) {
        console.log("invalid");
        console.log(this.registerForm.value);
        return;
      } else {
        console.log("submit");
        this.showUpdateDialog();
      }
    }
  }

  // status の状態を変更する
  onStatusChange(v:number) {
    this.updateShopStatus(v);
  }

  /* ===== メソッド ===== */

  // ローディングを表示する
  showLoading() {
    this.circleLoaderClass = "show";
  }
  
  // ローディングを隠す
  hideLoading() {
    this.circleLoaderClass = "hide";
  }

  // コンテンツを表示する
  showContents() {
    this.contentsClass = "show";
  }

  // コンテンツを非表示する
  hideContents() {
    this.contentsClass = "hide";
  }

  // item 一覧を取得する
  getItems() {
    this.showLoading();
    this.hideContents();
    this.shopService.getItems().pipe(first()).subscribe(items => {
      if (items['status'] == "success") {
        this.showErrorNoItem = false;
        this.items = items['items'];
        this.registerForm.controls.rank.setValue(this.items.length + 1);
      } else if (items['status'] == "no item") {
        this.showErrorNoItem = true;
        this.registerForm.controls.rank.setValue(1);
      } else {
        this.showErrorNoItem = false;
      }
      this.hideLoading();
      this.showContents();
    });
  }

  // カテゴリ一覧を取得する
  getCategories() {
    this.showLoading();
    this.hideContents();
    this.shopcategoryService.getItems().pipe(first()).subscribe(items => {
      if (items['status'] == "success") {
        this.showErrorNoCategory = false;
        /*
        this.categoryTitles.push("なし");
        this.categoryIds.push("0");
        this.isCategorySelectedArray[0] = true;
        this.isCategoryArray[0] = "なし";
        this.registerForm.controls.category_id.setValue("0");
        */
        for (let i = 0; i < items['items'].length; i++) {
          let v = items['items'][i]['id'];
          this.categoryTitles.push(items['items'][i]['title']);
          this.categoryIds.push(v);
          this.itemListCategoryTitles[v] = items['items'][i]['title'];
          this.isCategorySelectedArray[v] = false;
          this.isCategoryArray[v] = items['items'][i]['title'];
        }
        console.log(this.categoryTitles);
        this.onCategoryDropdownChange(1);
      } else if (items['status'] == "no item") {
        this.showErrorNoCategory = true;
      } else {
        this.showErrorNoCategory = false;
      }
      this.getItems();
    });
  }

  // 新規登録モードに変更する
  registerMode() {
    this.currentMode = "register";
    this.actionButtonLabel = "登録する";
    this.registerForm.reset();
    this.updateShopStatus(1);
    //this.form.controls.title.setValue('');
    this.cancelButtonClass = "spectrum-Button nd-Button-cancel hide";
  }

  // 編集モードに変更する
  editMode() {
    this.currentMode = "edit";
    this.actionButtonLabel = "編集する";
    this.registerForm.controls.id.setValue(this.item.id);
    this.registerForm.controls.title.setValue(this.item.title);
    this.registerForm.controls.category_id.setValue(this.item.category_id);
    this.updateShopStatus(this.item.status);
    this.registerForm.controls.rank.setValue(this.item.rank);
    this.cancelButtonClass = "spectrum-Button nd-Button-cancel";
  }

  // ラジオボタンを更新する
  updateShopStatus(v: number) {
    this.shopStatus[0] = false;
    this.shopStatus[1] = false;
    this.shopStatus[v] = true;
    this.registerForm.controls.status.setValue(v);
  }

  /* ===== アクション ===== */

  // 登録する
  registerAction() {
    this.hideContents();
    this.showLoading();
    var data_ = new FormData();
    data_.append('title', this.registerForm.value['title']);
    data_.append('category_id', this.registerForm.value['category_id']);
    data_.append('status', this.registerForm.value['status']);
    data_.append('rank', this.registerForm.value['rank']);
    this.shopService.register(data_).pipe(first()).subscribe(items => {
      this.showAlert();
      this.getItems();
      this.registerMode();
    });
  }

  // 編集する
  updateAction() {
    this.hideContents();
    this.showLoading();
    var data_ = new FormData();
    data_.append('id', this.registerForm.value['id']);
    data_.append('title', this.registerForm.value['title']);
    data_.append('category_id', this.registerForm.value['category_id']);
    data_.append('status', this.registerForm.value['status']);
    data_.append('rank', this.registerForm.value['rank']);
    this.shopService.update(data_).pipe(first()).subscribe(items => {
      this.showUpdateCompeteAlert();
      this.getItems();
      this.registerMode();
    });
  }

  // 削除する
  deleteAction() {
    this.hideContents();
    this.showLoading();
    this.shopService.delete(this.item).pipe(first()).subscribe(items => {
      this.showDeleteCompeteAlert();
      this.getItems();
      this.registerMode();
    });
  }

  /* ===== 確認ダイアログ ===== */

  /** 
   * 新規登録確認ダイアログを表示する
   * array[
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
   showRegisterDialog() {
    // ダイアログに命令を登録する
    var order: string[] = ["app-calendar-shop", "register"];
    this.dialogService.setOrder(order);
    var dialogArray: string[] = ['登録する','登録しない','店舗・イベントを登録しますか?','登録する場合は「登録する」ボタンをクリックしてください。','nd-Button-register'];
    this.dialogService.requestDialog(dialogArray);
  }

  /** 
   * 編集確認ダイアログを表示する
   */
   showUpdateDialog() {
    // ダイアログに命令を登録する
    var order: string[] = ["app-calendar-shop", "update"];
    this.dialogService.setOrder(order);
    var dialogArray: string[] = ['編集する','編集しない','店舗・イベントを編集しますか?','編集する場合は「編集する」ボタンをクリックしてください。','nd-Button-update'];
    this.dialogService.requestDialog(dialogArray)
  }

  /** 
   * 削除確認ダイアログを表示する
   */
  showDeleteDialog() {
    // ダイアログに命令を登録する
    var order: string[] = ["app-calendar-shop", "delete"];
    this.dialogService.setOrder(order);
    var dialogArray: string[] = ['削除する','削除しない','店舗・イベントを削除しますか?','「' + this.item.title + '」を削除する場合は「削除する」ボタンをクリックしてください。','nd-Button-warning'];
    this.dialogService.requestDialog(dialogArray)
  }


  /* ===== 完了アラート ===== */

  /** 
   * アラートを表示する
   * array[
   *  アラートの種類（info, help, success, warning, error）,
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
   showAlert() {
    var alertArray: string[] = ['success','','','店舗・イベント','店舗・イベントを登録しました'];
    this.alertService.requestAlert(alertArray);
  }
  
  /** 
   * 更新完了アラートを表示する
   */
   showUpdateCompeteAlert() {
    var alertArray: string[] = ['info','','','店舗・イベント','店舗・イベントを更新しました'];
    this.alertService.requestAlert(alertArray);
  }

  /** 
   * 削除完了アラートを表示する
   */
  showDeleteCompeteAlert() {
    var alertArray: string[] = ['info','','','店舗・イベント','店舗・イベントを削除しました'];
    this.alertService.requestAlert(alertArray);
  }
  

}