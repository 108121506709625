import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// Service
import { AuthService } from '../../../services/auth.service';
import { AlertService } from '../../../services/alert.service';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {

  // ログインフォーム
  loginForm: FormGroup;

  // アラートを管理する
  alertSubscription: Subscription;

  // アラートの表示(show)・非表示(hide)を管理するクラス
  alertClass = "hide";
  
  // アラートの種類
  alertType ="info";

  // アラートの種類に応じて決まるクラス
  alertTypeClass = "";

  // アラートの種類別をクラスを管理する配列
  alertTypeClassArray: { [key: string]: string; } = {};

  // アラートの見出し部分のテキスト
  alertHeader = "";

  // アラートの本文のテキスト
  alertContent = "";

  // アラートの閉じるボタンのラベル
  alertCancelButtonLabel = "";

  // アラートの実行ボタンを隠す(true)か否か(false)
  alertActionButtonFlg = true;

  // アラートの実行ボタンのラベル
  alertActionButtonLabel = "";

  // アラート・ダイアログ用のマスクを隠す(true)か否か(false)
  alertMaskFlg = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
  ) {
    this.alertTypeClassArray['info'] = "spectrum-Alert spectrum-Alert--success";
    this.alertTypeClassArray['help'] = "spectrum-Alert spectrum-Alert--help";
    this.alertTypeClassArray['success'] = "spectrum-Alert spectrum-Alert--success";
    this.alertTypeClassArray['warning'] = "spectrum-Alert spectrum-Alert--warning";
    this.alertTypeClassArray['error'] = "spectrum-Alert spectrum-Alert--error";
    this.alertTypeClass = this.alertTypeClassArray[this.alertType];
  }



  /* ========== ライフサイクル ========== */


  // コンポーネントを初期化
  ngOnInit() {

    if (this.authService.currentAccount) {
      this.router.navigate(['calendar']);
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // アラートを管理する
    this.alertSubscription = this.alertService.alertEventObservable$.subscribe(
      (data) => {
        this.alertTypeClass = this.alertTypeClassArray[data[0]];
        this.alertHeader = data[3];
        this.alertContent = data[4];
        if (data[1] == "") {
          this.alertActionButtonFlg = true;
          this.alertCancelButtonLabel = "閉じる";
        } else {
          this.alertActionButtonFlg = false;
          this.alertActionButtonLabel = data[1];
          this.alertCancelButtonLabel = data[2];
        }
        this.showAlert();
      }
    );
  }



  /* ========== イベント ========== */

  // ログイン処理
  onSubmit() {
    if (this.alertClass == "show") {
      this.alertClass = "hide";
    }
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          console.log(data.status);
          if (data.status == "success") {
            this.router.navigate(["calendar"]);
          } else {
            this.onShowAlert();
          }
        },
        error => {
          console.log('Error');
          console.log(error);
          this.showAlert();
        }
      );
  }
  

  /** 
   * アラートを表示する
   * array[
   *  アラートの種類（info, help, success, warning, error）,
   *  アクションボタンを使う場合は実行時のボタンのラベル名,
   *  アクションボタンを使う場合はキャンセル時のボタンのラベル名,
   *  タイトル,
   *  説明文
   * ]
   */
  onShowAlert() {
    console.log("test");
    var alertArray: string[] = ['error','','','ログインに失敗しました','アカウント名とパスワードをご確認ください。'];
    this.alertService.requestAlert(alertArray);
  }


  // アラートの閉じるボタンをクリックしたとき
  public onClickAlertCancelButton() {
    this.hideAlert();
  }

  public onClickAlertActionButton() {
    
  }


  /* ========== アラート ========== */
  
  // アラートを表示する
  showAlert() {
    this.alertClass = "";
    var this_ = this;
    window.setTimeout(function() {
      console.log("timer");
      this_.alertClass = "show";
    },20);
  }
  
  // アラートを隠す
  hideAlert() {
    this.alertClass = "";
    var this_ = this;
    //this.alertClass = "hide";
    window.setTimeout(function() {
      this_.alertClass = "hide";
    },250);
  }

}
